.my-ad-row {
    width: 100%;
    height: 15vh;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
}
.my-ad-row:hover {
    box-shadow: 0 0 5px #b1b1b1;
}
.my-ad-row img {
    border-radius: 5px;
    width: 250px;
    height: 95px;
    object-fit: cover;
}
.my-ad-row .price {
    font-size: 22px;
    font-weight: bolder;
}
.my-ad-row .title {
    font-size: 18px;
    font-weight: bolder;
}
.my-ad-row .description {
    font-size: 14px;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.my-ad-row .footer {
    font-size: 14px;
    color: grey;
}
.my-ad-row .address {
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.badge {
    font-weight: normal;
    padding: 4px 8px;
}
i {
    margin-right: 10px;
}
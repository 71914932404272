.btn.btn-sm.btn-light.m-1.deactive {
  background-color: red;
  color: #e9ecef;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-color: red;
}

.btn.btn-sm.btn-light.m-1.soldout {
  background-color: #28a745;
  color: #e9ecef;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-color: #28a745;
}

.btn.btn-sm.btn-light.m1.delete {
  background-color: #060606;
  color: #e9ecef;
  font-weight: 500;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-color: #000000;
}

.img-logo {
  width: 100px;
  height: auto;
}

.img-logo-100 {
  width: 100px;
  height: auto;
}
.loading {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.4);
  z-index: 1000;
}
.loading-icon {
  color: black;
  position: fixed;
  top: 40%;
  left: 47%;
  width: 100px;
  height: 100px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}

.img-circle-header {
  width: 40px;
  height: auto;
  margin-right: 10px;
  margin-left: 10px;
  object-fit: cover;
  border: 1px solid seashell;
  border-radius: 50%;
}
.profile-image {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
  border: 1px solid seashell;
}
.user-dropdown i {
  margin-right: 10px;
}
.red {
  color: #fc2929;
}

.app-grid-row {
  font-size: 15px !important;
}

.btn-grid-row {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.user-ads {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashed {
  border: 1px dashed #ccc;
  padding: 10px;
  border-radius: 5px;
}
.nav-pills a {
  margin-bottom: 12px;
  font-size: 17px;
}
.login-first {
  margin: 15px;
}
.dropdown-menu a {cursor: pointer;}
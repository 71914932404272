.gallery-image img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    margin: 3px;
    border-radius: 5px;
    float: left;
}
.gallery-image:hover {
    opacity: 0.9;
}
.gallery {
    padding: 5px;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
}
.btn-delete-image {
    position: absolute;
    display: none;
    margin-top: 2px;
    margin-left: 7px;
    font-size: 22px;
    cursor: pointer;
    opacity: 1;
}
.gallery-image:hover .btn-delete-image {
    display: block;
}
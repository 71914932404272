.user-details i {
    margin-right: 5px;
    margin-left: 5px;
}
.user-details .badge {
    margin-left: 5px;
}
.user-details .phone {
    border: none;
    padding: 0px;
    display: inline;
}